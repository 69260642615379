import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from '../elements/Image';

const propTypes = {
  children: PropTypes.node,
  underline: PropTypes.bool,
  labelHidden: PropTypes.bool,
  id: PropTypes.string
}

const defaultProps = {
  children: null,
  labelHidden: false,
  id: null
}

const Feature = ({
  className,
  children,
  labelHidden,
  underline,
  id,
  ...props
}) => {
  const classes = classNames(
    'feature',
    labelHidden && 'screen-reader',
    className
  );

  const classSplit = classNames(
    "row center-content-mobile ",
  );

  const sideA = classNames(
    !props.flip ? "side-f1" : "side-f0",
  );
  const sideB = classNames(
    !props.flip ? "side-f0" : "side-f1",
  );

  const NumberList = props?.numberlist;

  return (
    <label
      {...props}
      className={classes}
      htmlFor={id}
    >
      <div className="row mt-32 mb-32" >

      <div className={sideA} />

        <div className={classSplit}  style={{flex: 1}}>
          <div className={
            classNames(
              'reveal-from-bottom',
              "mr-16",
              "number"
            )}
            data-reveal-container=".center-content-mobile">
              <Image
                src={NumberList}
                alt={props.heading}
              />
          </div>

          <div className="split-item-content center-content-mobile reveal-from-left underline-gradient" data-reveal-container=".center-content-mobile" style={{flex: 1}}>
            {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
            kikeer
              </div> */}
            <h3 className="mt-0">
            {props.heading}
              </h3>
            <h5 className="m-0">
            {props.text}                  
            </h5>
          </div>

        </div>

        <div className={sideB} />
        

      </div>
    </label>
  )
  ;
}

Feature.propTypes = propTypes;
Feature.defaultProps = defaultProps;

export default Feature;