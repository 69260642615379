import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Feature from '../elements/Feature';

// Images
import * as numbers from "../../assets/svgs/svg";
import Fallback from "../../assets/svgs/number1.svg"

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Roadmap',
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container features" id="features">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            {features.map((feature, i) => {
              const Numberlist = numbers[`Number${i + 1}`] || Fallback;
              return <Feature key={feature.id} {...feature} flip={Boolean(i % 2)} underline numberlist={Numberlist}/>
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;


const features = [
  {
    id: '1',
    kicker: null,
    heading: 'Arena',
    text: "Metaverse world with multiplayer support and spatial audio.",
    content: {
      id: 2
    }
  },
  {
    id: '2',
    kicker: null,
    heading: 'Listening parties',
    text: "The Optimal community will get together to listen to historic albums with an after-party. Limited capacity ",
    content: {
      id: 2
    }
  },
  {
    id: '3',
    kicker: null,
    heading: 'HiFi',
    text: "High Definition surround music. Audiophiles welcomed! ASMR Listening parties.",
    content: {
      id: 2
    }
  },
  {
    id: '4',
    kicker: null,
    heading: 'Live DJ set',
    text: "Soundbaths where people connect with their favourite DJs.",
    content: {
      id: 2
    }
  },
  {
    id: '5',
    kicker: null,
    heading: 'VR Support',
    text: "A totally immersive way to experience music.",
    content: {
      id: 2
    }
  },
  {
    id: '6',
    kicker: null,
    heading: 'Custom avatars',
    text: "Every person can express through their avatar and swag.",
    content: {
      id: 2
    }
  },
  {
    id: '7',
    kicker: null,
    heading: '"Unplugged" concert',
    text: "TBA",
    content: {
      id: 2
    }
  },
  {
    id: '8',
    kicker: null,
    heading: 'Stadium',
    text: "Enable support for big shows. This will include community produced concerts through crowdfunding. ",
    content: {
      id: 2
    }
  },
  {
    id: '9',
    kicker: null,
    heading: 'Retro Shows',
    text: "Bring back iconic shows to experience with other fans around the world.",
    content: {
      id: 2
    }
  },
]