import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Mailgun from 'mailgun.js';
import formData from 'form-data';

import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Modal from '../elements/Modal';
import Button from '../elements/Button';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const [address, setAddress] = useState("");
  const [modalActive, setModalActive] = useState(false);

  const DOMAIN = 'hello@sandbox1ac7b7ebfd564696a7523b5a286901f9.mailgun.org';

  const handleChange = (event) => {
    console.log(event)
    setAddress(event.target.value);
  }
  
  const mailgun = new Mailgun(formData);
  const client = mailgun.client({ username: 'api', key: process.env.REACT_APP_MAILGUN_API || '' });

  const handleEnter = async (event) => {
    if(event.keyCode === 13){
      event.preventDefault(); // Ensure it is only this code that runs
      alert("Enter was pressed was presses");
    }
  }

const handleSubmit = async (event) => {
  event.preventDefault();
  try {
    // const newMember = await client.lists.members.createMember(DOMAIN,
    //   {
    //       address: address,
    //       // name: 'Bob Barr',
    //       // vars: JSON.stringify({age: 27}),
    //       subscribed: 'yes',
    //       upsert: 'yes'
    //   }
    // );
    // console.log('newMember', newMember);
    openModal();
  } catch (error) {
      console.error(error);
  }
};
  
  const openModal = () => {
    setModalActive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setModalActive(false);
  }   
  
  const innerClasses = classNames(
    'cta-inner section-inner',
    "row",
    // topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  

  const ctaClasses = classNames(
    "container border-gradient z center-content",
    modalActive && "not-active-cta"
    );  
    
    const alertClasses = classNames(
    "container border-gradient center-content",
    modalActive ? "active-cta" : "not-active-cta"
  );  

  return (
    <section
      {...props}
      className="cta-inner section-inner center-content"
    >
      <div className={ctaClasses} id="cta">
        <h2 className="center">
            Go on a sensory journey and share with others
        </h2>
        <p className="m-0 mb-32 reveal-from-bottom center" >

          Be one of the first to participate in this experience
        </p>
        <div
          className={innerClasses}
        >
          <div className="cta-action mr-8">
            <Input id="newsletter" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Your email" value={address} onChange={handleChange} onKeyPress={handleEnter} >
              {/* <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
              </svg> */}
            </Input>
          </div>
          <div className="cta-slogan ml-8">
            <Button tag="a" className="button-full" onClick={handleSubmit}>
              Join waitlist
            </Button>
          </div>
        </div>
      </div>

      <div className={alertClasses} id="cta">
           <h2 className="center">🎉 Welcome onboard! 🎉</h2>
        <div
          className={innerClasses}
        >
          <Button href="https://discord.gg/aCv54gAK" tag="a" className="button-full" style={{margin: 5}}>Join us on discord!</Button>
        </div>
      </div>
    </section>


  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;