import GSAP from 'gsap'

export default class {
  constructor(element, camera) {
    this.element = element

    this.elements = {
      hero: element.querySelector('.hero'),
      cta: element.querySelector('.cta'),
      // TODO: Add features numbers
      features: element.querySelectorAll('.features'),
      footer: element.querySelector('.footer'),
    }

    this.camera = camera

    this.animateIn()
  }

  animateIn() {
    const animateIn = GSAP.timeline({ 
      defaults: {
        ease: 'expo'
      }
    })
    
    animateIn
      .from(this.camera.position, {
        z: 4,
        duration: 3
      })
      
      .from([ 
        this.elements.hero, 
        this.elements.cta, 
        this.elements.features, 
        this.elements.footer,
      ], {
          y: -100,
          autoAlpha: 0,
          stagger: .2,
          duration: 1.6
      }, '<.3')
    }
}
