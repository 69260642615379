import React from 'react';
import classNames from 'classnames';
import {
  Twitter,
  GitHub,
} from "react-feather"

import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'About us',
    // paragraph: 'Vitae aliquet nec ullamcorper sit amet risus nullam eget felis semper quis lectus nulla at volutpat diam ut venenatis tellus—in ornare.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container" id="about">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
                <div className="testimonial-item-content">
                <div className={
                    classNames(
                      'center-content-mobile reveal-from-bottom',
                      // imageFill && 'split-item-image-fill'
                      // "mr-16"
                    )}>
                      <Image
                        src={require("../../assets/images/rodri.jpeg")}
                        alt={props.heading}
                        className="profile-pic"
                      />
                  </div>
                  <div className="center-content">
                    <h4>
                      Rodri Castillo
                    </h4>
                    <div className="row">
                      <a href="https://twitter.com/_rodricast">
                        <Twitter />
                      </a>
                    </div>
                  </div>
                </div>
            </div>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
                <div className="testimonial-item-content">
                <div className={
                    classNames(
                      'center-content-mobile reveal-from-bottom center-content',
                      // imageFill && 'split-item-image-fill'
                      // "mr-16"
                    )}>
                      <Image
                        src={require("../../assets/images/petra.jpeg")}
                        alt={props.heading}
                        className="profile-pic"
                      />
                  </div>

                  <div className="center-content">
                    <h4>
                      Franco Petra
                    </h4>
                    <div className="row">
                      <a href="https://twitter.com/0xPetra" className='mr-8'>
                        <Twitter />
                      </a>
                      <a href="https://github.com/0xPetra" className='ml-8'>
                        <GitHub />
                      </a>
                    </div>
                  </div>
                </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;