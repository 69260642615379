import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import IsoLogo from '../layout/partials/IsoLogo';


const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {



  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm mt-32 mb-32">
        <div className={innerClasses}>
          <IsoLogo />
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            Listening parties
              {/* Landing template for <span className="text-color-primary">startups</span> */}
            </h1>
            <div className="container-xs">
              <h6 className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                  Connect deeper to music and community 
                  <br/>
                  through the metaverse.
                </h6>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;